import React from "react";
import AllGradeCS from "./AllGradeCS";

function IndexCS({ classId, subjectName, subject, reports, year }) {
  return (
    <div>
      <AllGradeCS
        subjectName={subjectName}
        subject={subject}
        reports={reports}
        year={year}
      />
    </div>
  );
}

export default IndexCS;
