import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const AllGardeAlain = ({ subjectName, subject, reports }) => {
  const userData = useSelector((state) => state.user);

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "0px 0px 2px #000" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "600", width: "100px" }}
            >
              Admission No
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Students
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              IBT Stanine <br />
              {`(${subjectName})`}
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              IBT Percentile
            </TableCell>

            {reports.rcs && reports.rcs.length ? (
              <TableCell align="center" sx={{ fontWeight: "600", width: 120 }}>
                End of the Year Report Grade March 24{" "}
              </TableCell>
            ) : (
              ""
            )}
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Target for Final Exam
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Improvement Steps
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Half Yearly Grade
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Final Grade March 25
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              On Target (Y/N)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.students && reports.students.length > 0 ? (
            reports.students.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{data.admission_number}</TableCell>
                  <TableCell align="center">{data.name}</TableCell>

                  <TableCell align="center">
                    {data.cat_four_score && data.cat_four_score.ibt_stanine_m
                      ? data.cat_four_score.ibt_stanine_m
                      : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {data.cat_four_score
                      ? data.cat_four_score.ibt_percentile
                      : "--"}
                  </TableCell>

                  {reports.rcs && reports.rcs.length ? (
                    <TableCell align="center">
                      {data.report_data &&
                      reports.rcs[0] &&
                      data.report_data[reports.rcs[0].report_console_id]
                        ? data.report_data[reports.rcs[0].report_console_id]
                            ._score._grade
                        : "--"}
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell width={110} align="center">
                    {data.target ? (
                      data.target
                    ) : (
                      <Box>
                        {/* <Typography variant="body1" fontSize={12}>
                        Select Grade
                      </Typography> */}
                        <Typography>A1</Typography>
                        {/* <FormControl sx={{ minWidth: 80 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={"A1"}
                        >
                          {reports.rc_grades.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl> */}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {data.improvement_step ? data.improvement_step : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {data.hf_term ? data.hf_term._grade : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {data.current_year_final && data.current_year_final._grade
                      ? data.current_year_final._grade
                      : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {data.saved_entry && data.is_target ? (
                      <Typography
                        sx={{
                          border: "1px solid #6ede8a",
                          backgroundColor: "#6ede8a",
                        }}
                      >
                        Yes
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          border: "1px solid #ec8385",
                          backgroundColor: "#ec8385",
                        }}
                      >
                        No
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={11} align="center">
                No Report Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllGardeAlain;
