import React from "react";
import AllGradeSHJ from "./AllGradeSHJ";

function IndexSHJ({ classId, subjectName, subject, reports, year }) {
  return (
    <div>
      <AllGradeSHJ
        subjectName={subjectName}
        subject={subject}
        reports={reports}
        year={year}
      />
    </div>
  );
}

export default IndexSHJ;
