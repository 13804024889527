import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ApiService from "services/apiService2";
import CommonService from "services/commonService";
import { useDispatch } from "react-redux";
import { IndeterminateCheckBoxRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import IconLogo from "components/IconLogo";
import Index from "./Report_Template/DXB/Index";
import IndexSHJ from "./Report_Template/SSHJ/IndexSHJ";
import IndexAlain from "./Report_Template/Alain/IndexAlain";
import IndexCS from "./Report_Template/Central_school/IndexCS";

const TeachersReport = (props) => {
  const [page, setPage] = useState(0);
  const [classBatches, setClassBatch] = useState([]);
  const [acdYears, setYears] = useState([]);
  const [subjects, setClassSubjects] = useState([]);
  const [reports, setReports] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [classes, SetClass] = useState("");
  const [subject, SetSubject] = useState("");
  const [subjectName, SetSubjectName] = useState("Mathematics");
  const [batch, setBatchId] = useState("");
  const [classId, setClassId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [curmId, setCuriId] = useState("");
  const [year, setYear] = useState("");
  const classArr = useSelector((state) => state.classes);
  let url_params = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  // console.log(classId);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        user_id: props.teacherId,
        activeAcd: userData.academic_year,
        school_id: userData.school_id,
      };
      let apiResult = await ApiService.post("getown/classes/teacher", req);
      const data = apiResult.details.response;
      await data.data.sort(sorter);
      setClassBatch(data.data ? data.data : "");
      SetClass(data.data[0] ? data.data[0]?.academic : "");
      setYears(data.academic_years ? data.academic_years.list : "");
      setYear(
        data.academic_years.list[6]
          ? data.academic_years.list[6].name
          : data.academic_years.list[5].name
      );
      const found = data.data.filter((obj) => {
        return obj.academic === data.data[0].academic;
      });
      setClassSubjects(found[0] ? found[0].subject_array : "");
      setBatchId(data.data[0] ? data.data[0].batch_id : "");
      setClassId(data.data[0] ? data.data[0].class_id : "");
      SetSubject(found[0] ? found[0].subject_array[0]._id : "");
      SetSubjectName(found[0] ? found[0].subject_array[0].name : "");
      setSessionId(found[0] ? found[0].session_id : "");
      setCuriId(found[0] ? found[0].curriculum_id : "");
      await getReportList(
        data.academic_years.list[6]
          ? data.academic_years.list[data.academic_years.list.length - 1].name
          : "",
        data.data[0] ? data.data[0].batch_id : "",
        data.data[0] ? data.data[0].class_id : "",
        found[0] ? found[0].subject_array[0]._id : "",
        found[0] ? found[0].session_id : "",
        found[0] ? found[0].curriculum_id : ""
      );
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function sorter(a, b) {
    const [numB, alphaB] = b.academic ? b.academic.split("-") : "";
    const [numA, alphaA] = a.academic ? a.academic.split("-") : "";

    if (numA !== numB) {
      return parseInt(numA) - parseInt(numB);
    }

    return alphaA.localeCompare(alphaB);
  }

  const handleClassChange = async (e) => {
    SetClass(e.target.value);
    const found = classBatches.filter((obj) => {
      return obj.academic === e.target.value;
    });
    toast.success(found[0].academic + " is selected");
    if (found[0]) {
      setClassSubjects(found[0].subject_array);
      setBatchId(found[0].batch_id);
      setClassId(found[0].class_id);
      setSessionId(found[0].session_id);
      setCuriId(found[0].curriculum_id);
      SetSubject(found[0].subject_array[0]._id);
      SetSubjectName(found[0].subject_array[0].name);
    }
    if (year && subject) {
      await getReportList(
        "",
        found[0].batch_id,
        found[0].class_id,
        found[0].subject_array[0]._id,
        found[0].session_id,
        found[0].curriculum_id
      );
    }
  };

  const handleSubjectChange = async (e) => {
    SetSubject(e.target.value);
    const sub = subjects.filter((obj) => {
      return obj._id === e.target.value;
    });
    toast.success(sub[0].name + " is selected");
    SetSubjectName(sub[0].name);
    if (classId && batch && year) {
      await getReportList("", "", "", e.target.value);
    }
  };

  const handleYearChange = async (e) => {
    setYear(e.target.value);
    toast.success(e.target.value + " is selected");
    if (classId && batch && subject) {
      await getReportList(e.target.value);
    }
  };

  const getReportList = async (
    selectedYear,
    bat,
    classI,
    sub,
    session,
    curi
  ) => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        session: session ? session : sessionId,
        curriculum: curi ? curi : curmId,
        class: classI ? classI : classId,
        batch: bat ? bat : batch,
        school_id: userData.school_id,
        teacher_class_story: true,
        subject_id: sub ? sub : subject,
        selected_year: selectedYear ? selectedYear : year,
        is_pdf: false,
        FILE_UPLOAD_URL: "https://teamsqa4000.educore.guru",
        user_id: props.teacherId,
      };
      let apiResult = await ApiService.post("get/class/story/eoy", req);
      const data = apiResult.details.response;
      setReports(data);
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const pdfDownload = async (selectedYear) => {
    toast.success("Downloading PDF");
    try {
      CommonService.loaderON(dispatch);

      const req = {
        session: sessionId,
        curriculum: curmId,
        class: classId,
        batch: batch,
        school_id: userData.school_id,
        teacher_class_story: true,
        subject_id: subject,
        selected_year: selectedYear || year,
        is_pdf: true,
        FILE_UPLOAD_URL: "https://teamsqa4000.educore.guru",
        user_id: props.teacherId,
      };

      const apiResult = await ApiService.post("get/class/story/eoy", req);
      const data = apiResult.details.response;

      const base64Data = data.replace(/^data:application\/pdf;base64,/, "");
      const binaryData = atob(base64Data);
      const uint8Array = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      const pdfData = new Blob([uint8Array], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfData);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Report.pdf";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3} gap={1}>
          <Typography>Please Select your Class</Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Select
              labelId="demo-simple-select-label"
              id="Select_class"
              onChange={handleClassChange}
              value={classes}
              sx={{ background: "#3699FF", color: "white" }}
            >
              {classBatches && classBatches.length
                ? classBatches.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.academic}>
                        {row.class + " " + row.batch}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography>Please Select the Subject.</Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Select
              labelId="demo-simple-select-label"
              id="select_subject"
              value={subject}
              onChange={handleSubjectChange}
            >
              {subjects && subjects
                ? subjects.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row._id}>
                        <Box display={"flex"}>
                          {row.name === "English" && (
                            <IconLogo size="20" image="/assets/Book.png" />
                          )}
                          {row.name === "Mathematics" && (
                            <IconLogo size="20" image="/assets/maths.png" />
                          )}
                          {row.name === "Science" && (
                            <IconLogo size="20" image="/assets/science.png" />
                          )}
                          <Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {row.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography>Select Academic Year.</Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            {/* <InputLabel id="demo-simple-select-label">Year</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="select_Ac_year"
              // label="Year"
              value={year}
              onChange={handleYearChange}
              sx={{ background: "#3699FF", color: "white" }}
            >
              {acdYears.map((row, index) => {
                return (
                  <MenuItem key={index} value={row.name}>
                    {row.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={3}>
          <Typography>End of the year reports</Typography>
          <Box mt={1}>
            <Button
              variant="contained"
              sx={{ textAlign: "left", background: "#3699FF", color: "white" }}
            >
              {" "}
              <AssignmentIcon fontSize="large" />{" "}
              {reports.rcs && reports.rcs.length
                ? reports.rcs[0].report_card_name
                : "NIL"}
              <br /> ({reports.last_year})
            </Button>
          </Box>
        </Grid>

        {/* <Grid
          container
          mt={4}
          sx={{ border: "1px solid #E5E5E5" }}
          display={"flex"}
        > */}
        <Grid
          item
          xs={12}
          md={2}
          lg={1.5}
          p={2}
          mt={4}
          sx={{ border: "1px solid #E5E5E5" }}
        >
          Cohort : {reports.students ? reports.students.length : 0}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2.5}
          p={2}
          mt={4}
          sx={{ border: "1px solid #E5E5E5" }}
        >
          Asset attended count :{" "}
          {reports.total_attended ? reports.total_attended : 0}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          lg={3}
          p={2}
          mt={4}
          sx={{ border: "1px solid #E5E5E5" }}
        >
          Half Yearly Report :{" "}
          {reports.curr_hfterms && reports.curr_hfterms[0]
            ? reports.curr_hfterms[0].report_name
            : "NIL"}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          lg={3}
          p={2}
          mt={4}
          sx={{ border: "1px solid #E5E5E5" }}
        >
          Final Report :{" "}
          {reports.curr_final_terms && reports.curr_final_terms[0]?._id
            ? reports.finalRcObj[reports.curr_final_terms[0]?._id].console_name
            : "NIL"}
        </Grid>
        <Grid item xs={12} lg={1.6} mt={3} height={55} ml={"auto"}>
          <Button
            sx={{
              marginLeft: "auto",
              width: { xs: "100%", lg: "auto" },
              height: 50,
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            variant="outlined"
            onClick={() => {
              pdfDownload();
            }}
          >
            <img
              src="/assets/exportButton.png"
              alt=""
              style={{ height: 30, width: 30, paddingRight: 2 }}
            />
            Export Report
          </Button>
        </Grid>
        {/* </Grid> */}

        <Grid item xs={12} mt={4}>
          {userData.school_id === "CPpbKPQTcuG97i3kv" ? (
            <Index
              classId={classId}
              subjectName={subjectName}
              subject={subject}
              year={year}
              reports={reports}
            />
          ) : userData.school_id === "ps4vyLJhQvPZjfxaH" ? (
            <IndexSHJ
              classId={classId}
              subjectName={subjectName}
              subject={subject}
              year={year}
              reports={reports}
            />
          ) : userData.school_id === "2FwuqhgeoKt6SQiCG" ? (
            <IndexAlain
              classId={classId}
              subjectName={subjectName}
              subject={subject}
              year={year}
              reports={reports}
            />
          ) : userData.school_id === "m2LMtqaESFZf6xDE8" ? (
            <IndexCS
              classId={classId}
              subjectName={subjectName}
              subject={subject}
              year={year}
              reports={reports}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeachersReport;
