import React from "react";
import AllGardeAlain from "./AllGardeAlain";

const IndexAlain = ({ classId, subjectName, subject, reports, year }) => {
  return (
    <div>
      <AllGardeAlain
        subjectName={subjectName}
        subject={subject}
        reports={reports}
        year={year}
      />
    </div>
  );
};

export default IndexAlain;
