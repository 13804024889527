import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const Grade1 = ({ subjectName, subject, reports }) => {
  const userData = useSelector((state) => state.user);

  const last_year = reports && reports.last_year && reports.last_year.slice(7);

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "0px 0px 2px #000" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "600", width: "100px" }}
            >
              Comp No.
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Name of the student
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Reading 1 <br />
              May {last_year}
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Reading 2 <br />
              Oct {last_year}
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Reading 3 <br /> Feb {parseInt(last_year) + 1}
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              {subjectName === "English"
                ? "Verbal"
                : subjectName === "Mathematics"
                ? "Quantitative"
                : subjectName === "Science"
                ? "Spatial"
                : subjectName === "Biology"
                ? "Spatial"
                : subjectName === "Physics"
                ? "Spatial"
                : subjectName === "Chemistry"
                ? "Spatial"
                : "Spatial"}{" "}
              Stanine
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Asset {subjectName}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600", width: 150 }}>
              End of Year Report Grade March {last_year}
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Target for March {parseInt(last_year) + 1}
            </TableCell>

            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Improvement Steps
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Half Yearly Grade Sept {last_year}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Final Grade March {parseInt(last_year) + 1}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              On Target (Y/N)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.students && reports.students.length > 0 ? (
            reports.students.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{data.admission_number}</TableCell>
                  <TableCell align="center">{data.name}</TableCell>

                  <TableCell align="center">
                    {data.cat_sco && data.cat_sco.length
                      ? data.cat_sco.find((item) => item.order === 3)?.stanine3
                      : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {data.cat_sco && data.cat_sco.length
                      ? data.cat_sco.find((item) => item.order === 2)?.stanine2
                      : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {data.cat_sco && data.cat_sco.length
                      ? data.cat_sco.find((item) => item.order === 1)?.stanine
                      : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {data.cat_four_score
                      ? subjectName === "English"
                        ? data.cat_four_score.verbal_stanine
                        : subjectName === "Mathematics"
                        ? data.cat_four_score.quantitative_stanine
                        : subjectName === "Science"
                        ? data.cat_four_score.spatial_stanine
                        : subjectName === "Biology"
                        ? data.cat_four_score.spatial_stanine
                        : subjectName === "Physics"
                        ? data.cat_four_score.spatial_stanine
                        : subjectName === "Chemistry"
                        ? data.cat_four_score.spatial_stanine
                        : ""
                      : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {data.asset_score && data.asset_score.stanine
                      ? data.asset_score.stanine
                      : "--"}
                  </TableCell>

                  <TableCell align="center" sx={{ width: 150 }}>
                    {reports.rcs && reports.rcs.length ? (
                      <>
                        {data.report_data &&
                        reports.rcs[0] &&
                        data.report_data[reports.rcs[0].report_console_id]
                          ? data.report_data[reports.rcs[0].report_console_id]
                              ._score._grade
                          : "--"}
                      </>
                    ) : (
                      ""
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {data.target ? (
                      data.target
                    ) : (
                      <Box>
                        {/* <Typography variant="body1" fontSize={12}>
                                Select Grade
                              </Typography> */}
                        <Typography>A1</Typography>
                        {/* <FormControl sx={{ minWidth: 80 }}>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  defaultValue={"A1"}
                                >
                                  {reports.rc_grades.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl> */}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <TextareaAutosize
                      mt={{ xs: 1, md: 0 }}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      maxRows={5}
                      style={{ width: "150px" }}
                      disabled
                      value={data.improvement_step ? data.improvement_step : ""}
                    />
                    {/* {data.improvement_step ? data.improvement_step : "--"} */}
                  </TableCell>
                  <TableCell align="center">
                    {data.hf_term ? data.hf_term._grade : "NILL"}
                  </TableCell>
                  <TableCell align="center">
                    {data.current_year_final && data.current_year_final._grade
                      ? data.current_year_final._grade
                      : "NILL"}
                  </TableCell>
                  <TableCell align="center">
                    {data.saved_entry && data.is_target ? (
                      <Typography
                        sx={{
                          border: "1px solid #6ede8a",
                          backgroundColor: "#6ede8a",
                        }}
                      >
                        Yes
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          border: "1px solid #ec8385",
                          backgroundColor: "#ec8385",
                        }}
                      >
                        No
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={11} align="center">
                No Report Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Grade1;
